@import url("https://cdn.jsdelivr.net/gh/jgthms/minireset.css@master/minireset.min.css");

@import './sass/mixins';

@import 'sass/_colors';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px transparent;
  color: rgba(0, 0, 0, 0.84);
}

html {
  font-size: 8px;
}
@include breakpoint(phablet) {
  html {
    font-size: 10px;
  }
}
@include breakpoint(phablet) {
  html {
    font-size: 10px;
  }
}
@include breakpoint(tablet) {
  html {
    font-size: 12px;
  }
}
