@import "../../sass/colors";
@import "../../sass/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 1.25rem;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: $navbarHeight;
  z-index: 20;

  transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &--primary {
    background-color: $green;
  }

  &--secondary {
    background-color: $peach;
  }
  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    width: 35px;
    height: calc(#{$navbarHeight} - 1.25rem);
    margin-right: 1rem;
    fill: $black;
  }

  &__brandname {
    font-family: Montserrat;
    font-weight: 800;
    font-size: 2rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    color: $black;
  }

  &--hidden {
    top: -#{$navbarHeight};
  }

  &__version-selector-btn {
    padding: 1rem;
    border: solid 2px $black;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 1.7px;
    text-align: center;
    color: #473d3d;
    text-transform: uppercase;
    background: none;
    outline: none;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      touch-action: none;
    }
  }

  &__version-selector {
    background: $light-green;
    box-shadow: 0 3px 4px 0 rgba(50, 50, 50, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 3rem;
    display: flex;
    flex-direction: column;
    transform: translateY(-125%);
    transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 10;

    &--shown {
      transform: translateY(#{$navbarHeight});
    }
  }

  &__version {
    background: $white;
    border: none;
    padding: 1rem 1.25rem;
    text-align: center;
    font-family: Montserrat;
    font-weight: bolder;
    font-size: 1.5rem;
    letter-spacing: 2px;
    line-height: 1.17;
    color: $black;
    width: 100%;
    text-decoration: none;
    cursor: pointer;

    &--selected {
      background: $light-green;
    }
  }
}
