@import "../../sass/variables";
@import "../../sass/colors";
@import "../../sass/mixins";

.sidebar-background {
  background-color: #f2eeec9c;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &--hidden {
    display: none;
  }
}

.sidebar {
  $self: &;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background-color: $white;
  box-shadow: -3px 0 4px 0 rgba(185, 185, 185, 0.5);
  transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(0);

  &__title-container {
    height: $navbarHeight;
    background-color: $black;
    padding: 0 1.25rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    #{$self}__title {
      font-family: Montserrat;
      font-weight: 800;
      font-size: 2rem;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-right: 1.25rem;
      color: $light-green;
      text-align: center;
    }
  }

  &__container {
    width: 100%;
    min-height: calc(100% - $navbarHeight - 75px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow-y: scroll;
    padding-top: 1rem;

    #{$self}__item {
      padding: 1rem 1.25rem;
      text-align: right;
      font-family: Vollkorn;
      font-size: 2rem;
      line-height: 1.17;
      color: $black;
      width: 100%;
      text-decoration: none;

      &--page {
        font-weight: normal;
      }
      &--part {
        font-weight: bold;
        user-select: none;
      }
      &--active {
        background-color: $peach;
        border-right: 0.625rem solid $green;
      }
    }
  }

  &__footer {
    height: 75px;
    background-color: $green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-family: Vollkorn;
  }

  &--hidden {
    transform: translateX(102%);
  }
}
@include breakpoint(phablet) {
  .sidebar {
    max-width: 100vw;
  }
}
@include breakpoint(tablet) {
  .sidebar {
    max-width: 40vw;
  }
}

@include breakpoint(laptop) {
  .sidebar {
    max-width: 30vw;
  }
}
