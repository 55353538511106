@import '../../sass/colors';

.error {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $white;

  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    text-align: center;

    & p {
      padding: 0 3.625rem;
      text-align: center;
      font-family: 'Vollkorn';
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: 1.4px;
      color: $black;
    }

    & button {
      margin-top: 4rem;
      border: solid 2px $black;
      width: 27.625rem;
      height: 8.625rem;
      font-family: Montserrat;
      font-weight: 700;
      font-size: 1.75rem;
      letter-spacing: 1.7px;
      text-align: center;
      color: #353535;
      text-transform: uppercase;
      background: none;
      outline: none;
      cursor: pointer;
    }
  }
}
