@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $point == laptop {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == phablet {
    @media (min-width: 375px) {
      @content;
    }
  } @else if $point == mobileonly {
    @media (max-width: 320px) {
      @content;
    }
  }
}

@mixin fonting(
  $f: $subheader-font-stack,
  $s: 1em,
  $w: 400,
  $ls: 0px,
  $lh: 1em,
  $tt: none
) {
  font-family: $f;
  font-size: $s;
  font-weight: $w;
  letter-spacing: $ls;
  line-height: $lh;
  text-transform: $tt;
}
