@import '../../sass/colors';
@import '../../sass/variables';

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &__button {
    box-shadow: 0px 3px 4px 1px rgba(128, 128, 128, 0.502);
    width: 4.75rem;
    height: 4.75rem;
    background-color: rgb(250, 250, 250);
    border-radius: 50%;
    border: none;
    color: $black;
    outline: none;
    font-size: 1.5rem;
  }

  &--hidden {
    bottom: -#{$navbarHeight};
  }
}
