@import "../../sass/variables";
@import "../../sass/colors";

.landing {
  width: 100%;
  height: calc(100vh - #{$navbarHeight});

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo {
    max-width: 200px;
    max-height: 200px;
  }

  &__title {
    font-family: "Vollkorn";
    font-size: 3.25rem;
    font-weight: bold;
    line-height: 1;
    color: $black;
    text-align: center;
    margin-top: 4rem;
  }


  &__quote {
    margin-top: 1rem;
    padding: 0 3.625rem;
    text-align: center;
    font-family: "Vollkorn";
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 1.4px;
    color: $black;
  }

  &__cta {
    margin-top: 4rem;
    border: solid 2px $black;
    width: 27.625rem;
    height: 8.625rem;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1.75rem;
    letter-spacing: 1.7px;
    text-align: center;
    color: #353535;
    text-transform: uppercase;
    background: none;
    outline: none;
    cursor: pointer;
  }
}
